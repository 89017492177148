import React from 'react';
import { Survey } from 'survey-react-ui'; // SurveyJS bileşeni
import 'survey-core/modern.min.css'; // SurveyJS modern teması
import { StylesManager, Model, surveyLocalization } from 'survey-core'; // SurveyJS Modeli ve temalar
import 'survey-core/survey.i18n'; // Tüm dilleri içeren dosya
import './Survey.css'; // CSS dosyamızı import ediyoruz

function SurveyComponent() {
  // SurveyJS'nin dilini Türkçe olarak ayarlıyoruz
  surveyLocalization.currentLocale = 'tr';

  // SurveyJS Temasını Özelleştiriyoruz
  StylesManager.applyTheme("modern"); // Modern temayı uygula

  // Anketin JSON formatında tanımını yapıyoruz
  const surveyJson = {
    showProgressBar: "top",
    completedHtml: "<h2>Teşekkürler!</h2><p>Anketi doldurduğunuz için teşekkür ederiz.</p>",
    goNextPageAutomatic: false, // Otomatik sayfa geçişi yok, butonla geçiş yapılacak
    pages: [
      {
        title: "Sayfa 1",
        questions: [
          {
            type: "text",
            name: "isim",
            title: "Adınız nedir?",
            isRequired: true,
          },
        ],
      },
      {
        title: "Sayfa 2",
        questions: [
          {
            type: "radiogroup",
            name: "cinsiyet",
            title: "Cinsiyetiniz nedir?",
            isRequired: true,
            choices: ["Erkek", "Kadın", "Diğer"],
          },
        ],
      },
      {
        title: "Sayfa 3",
        questions: [
          {
            type: "text",
            name: "email",
            title: "E-posta adresinizi girin",
            isRequired: true,
            validators: [
              {
                type: "email",
              },
            ],
          },
        ],
      },
    ],
  };

  const survey = new Model(surveyJson); // SurveyJS modelini oluşturuyoruz

  survey.onComplete.add((result) => {
    console.log("Anket tamamlandı:", result.data);
  });

  return (
    <div className="survey-container"> {/* Anketi ortalamak için konteyner */}
      <Survey model={survey} /> {/* SurveyJS bileşenini render ediyoruz */}
    </div>
  );
}

export default SurveyComponent;
