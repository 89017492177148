import React from "react";
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Survey from './survey/Survey'; // Survey bileşenini içe aktarıyoruz

function Home() {
  return (
    <main className="content">
      <h1 className="logo-text">Evde Yemek Yok</h1>
      <br />
      <img src={`../logo.png`} alt="Logo" className="responsive-logo" />
      <br />
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
        at mi nec diam fermentum ultricies. Nullam in semper elit. Sed
        sollicitudin, nunc nec vestibulum lacinia, nisl purus consequat libero,
        nec ultricies elit nisl nec eros. Nullam in semper elit. Sed
        sollicitudin, nunc nec vestibulum.
      </p>
      <br />

      {/* Butona Link ile yönlendirme ekliyoruz */}
      <Link to="/survey" className="survey-button">
        Hemen Bilgi Al
      </Link>
    </main>
  );
}

function App() {
  return (
    <Router>
      <div className="container">
        {/* Route tanımlamaları */}
        <Routes>
          {/* Anasayfa rotası */}
          <Route path="/" element={<Home />} />

          {/* Survey sayfası rotası */}
          <Route path="/survey" element={<Survey />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
